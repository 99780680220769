<script lang="ts">
import Popover from '@igefa-e-commerce/pattern-library/src/components/Popover/Popover.svelte'
import type { Customer } from 'buy-user-application-domain/model/Customer.ts'
import MyAccountCustomerInfo from '../my-account-customer-info/MyAccountCustomerInfo.svelte'
import MyAccountNavigation from '../my-account-navigation/MyAccountNavigation.svelte'

export let open = false
export let customer: Customer | undefined = undefined
</script>

<Popover dataTestId='my-account-popover' title="" placement="bottom" {open} on:close>
  <svelte:fragment slot="target">
    <slot />
  </svelte:fragment>

  <svelte:fragment slot='popover'>
    {#if customer}
      <MyAccountCustomerInfo {customer}/>
      <MyAccountNavigation />
    {/if}
  </svelte:fragment>
</Popover>

<style lang="scss">
  :global(.popover .my-account-navigation) {
    margin-bottom: 0 !important;
  }
</style>
